import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Home } from "assets/images/svg/HomeFilled.svg";
import { setUserProfile } from "store/slices/userSlice";
import { getUserInfo, logout } from "services/auth";
import { getImageUrl } from "utils/commonFunctions";
import { OverlayPanel } from "primereact/overlaypanel";
import Logoutblack from "assets/images/svg/Logoutblack.svg";
import { Link, useNavigate } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";

export default function Topbar({ toggleSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState("");
  const [userName, setUserName] = useState("Loading...");
  const op = useRef(null);
  useEffect(() => {
    if (!profile?.id) {
      const info = getUserInfo();
      dispatch(setUserProfile(info));
      setUserInfo(info);
    } else {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (userInfo?.first_name && userInfo?.last_name) {
      const username = `${userInfo?.first_name} ${userInfo?.last_name}`;
      setUserName(username);
    }
  }, [userInfo]);

  let userimage = userInfo?.image;
  const getFirstCharacter = (name = "") => {
    let words = name.split(" ");
    let firstLetters = words.map((word) => word?.charAt(0).toUpperCase());
    firstLetters = firstLetters.slice(0, 2);
    return firstLetters;
  };

  const onLogout = () => {
    confirmDialog({
      message: "Are you sure you want to logout?",
      className: "invitation-modal",
      header: "Confirmation",
      rejectClassName: "primary-button-outlined bg-transparent",
      acceptClassName: "primary-button",
      icon: "pi pi-sign-out",
      defaultFocus: "accept",
      accept: () => {
        logout(() => navigate("/"));
      },
    });
  };

  return (
    <div className="layout-topbar px-4">
      <div className="flex">
        <>
          <div className="my-auto cursor-pointer" onClick={toggleSidebar}>
            <i className="pi pi-bars text-2xl"></i>
          </div>
          <div className="flex my-auto ml-3">
            <Home className="my-auto" />
            <div className="page-title ml-1">Real Estate</div>
          </div>
        </>
      </div>

      <div className="top-menu">
        <div
          className="flex cursor-pointer"
          onClick={(e) => op.current.toggle(e)}
        >
          {userimage ? (
            <Avatar
              image={getImageUrl(userimage)}
              shape="circle"
              className="my-auto"
            />
          ) : (
            <Avatar
              label={getFirstCharacter(userInfo?.first_name)}
              size="small"
              shape="circle"
              className="my-auto"
            />
          )}
          <div className="flex ml-2 my-auto ">
            <span className="username firstletter_capital">{userName}</span>
          </div>
        </div>
        <OverlayPanel ref={op}>
          <div className="p-3 flex align-item-center gap-2">
            <img src={Logoutblack} />
            <Link
              className="text-black-alpha-90 no-underline"
              onClick={onLogout}
            >
              Logout
            </Link>
          </div>
        </OverlayPanel>
      </div>
    </div>
  );
}
