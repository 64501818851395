import React, { useMemo } from "react";
import { CustomForm } from "shared/AllInputs";
import { CustomViewInput } from "shared/AllViewInputs";
import CommonViewTable from "views/Tables/CommonViewTable";

export default function OfficeView({ data }) {
  const bankColumns = useMemo(
    () => [
      {
        name: "Purpose",
        accessor: "purpose",
      },
      {
        name: "Owner Name",
        accessor: "owner_name",
      },
      {
        name: "IBAN",
        accessor: "iban",
      },
      {
        name: "BIC",
        accessor: "bic",
      },
    ],
    []
  );
  return (
    <>
      <CustomForm title="General">
        <CustomViewInput name="officeId" data={data} label={"Office ID"} />
        <CustomViewInput name="officeName" data={data} />
        <CustomViewInput name="officeNickName" data={data} />
        <CustomViewInput name="officeEmail" data={data} />
        <CustomViewInput name="phoneNumber" data={data} />
        <CustomViewInput name="unit" data={data} />
        <CustomViewInput name="officePurpose" data={data} />
        <CustomViewInput
          name="officeRep"
          label="officeRepresentative"
          data={data}
        />
      </CustomForm>
      <CustomForm title="Address">
        <CustomViewInput name="address" data={data} />
        <CustomViewInput name="street" data={data} />
        <CustomViewInput name="zipCode" data={data} />
        <CustomViewInput name="city" data={data} />
        <CustomViewInput name="cityDivision" data={data} />
        <CustomViewInput name="country" data={data} />
      </CustomForm>
      <CustomForm title="Financial Details">
        <CustomViewInput name="costCenter" data={data} />
      </CustomForm>
      <CommonViewTable
        columns={bankColumns}
        list={data?.bankAccounts}
        title={"Bank Accounts"}
      />
    </>
  );
}
